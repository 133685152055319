@use "sass:color";
@use "sass:map";

@font-face {
  font-family: 'AvQest';
  font-weight: 100 900;
  font-style: normal;
  src: url('../assets/fonts/avqest.woff');
}

* {
  border-radius: 0 !important;
}

div ul.context-menu {
  top: 0;
  left: 0;
  margin: 0;
  display: none;
  list-style: none;
  z-index: 10;
  box-shadow: 0 3px 6px #000000;
  border-radius: 6px;

  width: 100px;
  text-align: center;

  li:first-of-type {
    margin-top: 4px;
  }

  li:last-of-type {
    margin-bottom: 4px;
  }
}

.bg-light {
  opacity: .8;
  background-color: #303030 !important;
}

body {
  background: url('../assets/images/background-body.png');
  background-repeat: repeat;
}

ul {
  list-style-type: none;
}

.react-contexify {
  background-color: map.get($appColors, "lblack");

  &__submenu--is-open,
  &__submenu--is-open > &__item__content {
  }

  &__submenu--is-open > &__submenu {
  }

  & &__submenu {
  }

  &__submenu-arrow {
  }

  &__separator {
  }

  &__will-leave--disabled {
  }

  &__item {

    &:focus{
    }

    &:not(&--disabled):hover > &__content,
    &:not(&--disabled):focus > &__content {
      background-color: color.scale(map.get($appColors, "blue"), $alpha: -36%);
    }

    &:not(&--disabled):hover > .react-contexify__submenu {
    }

    &--disabled {
    }
    &__content {
      color: var(--color-white);
    }
  }
}

#tabs-tabpane-quests,
#tabs-tabpane-waypoints {
  ul {
    padding: 0;
    & ul {
      padding-left: 2rem;
      & ul {
        margin-bottom: 1rem;
      }
    }
  }
}

#tabs-tabpane-items {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-file {
  &-label {
    height: calc(1.75em + 0.75rem);
    &::after {
      color: var(--color-white);
      height: calc(1.75em + 0.75rem);
    }
  }
}

.popover {
  text-align: center;
  font-family: 'AvQest', sans-serif;
  position: relative;
  background-color: rgba(37, 37, 37, 0.98);
  color: var(--color-white);
  border-radius: 1px;
  font-size: 16px;
  line-height: 1;
  outline: 0;
  opacity: 0.98;
  > .arrow {
    display: none;
  }
}

.grid {
  position: relative;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAkFBMVEXgpITgxJT85KSsnGTYuGQIBAQEBAQ0NDQsLCwwMDA4ODhERERAQEBISEhMTExUVFQcHBwMDAgMDAwICAgQEBAUFBQYGBiUgGRQUFAgICA8PDxwcHBgYGAkJCSEhIR8fHygoKCQkJA0ODycmJQoKCisrKwUEBDAoIB0dHRsbGxoaGhkZGRcXFxYWFh8dHD///96rxWVAAAAAWJLR0QvI9QgEQAAAAd0SU1FB+MMBhUdKRus5LoAAAAJdnBBZwAAAH0AAAAgADfqh7QAAAI2SURBVDjLfZPrYtowDIUTUpwLgcryBUwISYqzEbru/R9vRw7tuj8zmMTyZ1k6ElmWbzZ5XvwdWH8aXopNnuVb9b+xyYqyqjGaXVs2Tbvft7vDbr9r8d7uD6+qyArSzGzYWmM4DWudY2PM1vqdesmK2rOxyY6ZvsABK8XUwsPx1AR2Fsc8PmydlV2W+9mf5Yru0vbOeyfTAkn3GezjUQpwHS6ElUrTyEy/sjaVAOM0uG95JQ+sbCLeEjBO9B0wyIdNCsKUKs+Ot9sU+eu4QSCcEsdyG1fgFu2T8MF6Qr5GrQYHJQWo0yGc0X2g3rEyT48JGMex0loro7VzNgRK7sw/QNRbOIAI3opiQqxX+BTDONbkrQuRoLMyqIT/zDuI1PDQBHJU1cEmmS3KxOslUYB5HmvcTVrDOzasZ7Vm+dQBQkV2sCqdaoRgNcRIb/UT6Fkb86Wlo76ueukJlmIdp3Gu1tKs5aIQ+lhH8k678zPIKNpgkwMZ9lJ8Iis5r8BtfCNnFFrO9ZEs9YjAwcLO7QSADiUOpPylr7x0DSoCfm05pFk5aTpsSTMiUZuqbSzV0g/zPJ9/sGf2fm1FnEy1hvYC/BznuZV+RWSrPCx6oiMhTupJAKeqp0CxqgIqJbEE6CQBNfLPQpDTdbjfl/sw3B/vy9J1Q3c5VeScq1dA9jvYAeEh74/LZV/hzrMA03S9Dt0DwNLBSbcsy+PXx+XQ1hRPqEXxe7jD+ngsMkC84xXEx+uhKfcq/wNEN0Tadg4IdQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMi0wNlQyMToyOTo0MS0wNTowMM1r0B4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTItMDZUMjE6Mjk6NDEtMDU6MDC8NmiiAAAAAElFTkSuQmCC);
  background-repeat: repeat;
  margin: 1em auto;

  .cell {
    display: inline;
    position: absolute;
  }
}

.inventory {
  position: relative;
  margin: 1em auto;
  width: calc(var(--grid-size) * 10);
  height: calc(var(--grid-size) * 7);

  .right-tab {
    top: calc(var(--grid-size) * .50);
    left: calc((var(--grid-size) * 5) - (var(--grid-size) * 4.5));
    position: absolute;
  }

  .left-tab {
    top: calc(var(--grid-size) * .50);
    left: calc((var(--grid-size) * 5) + (var(--grid-size) * 2.5));
    position: absolute;
  }

  .tab {
    padding: 0 12px;
    height: 22px;
  }

  .layer {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }

  .item {
    background: none;
    width: 100%;
    height: 100%;
  }

  .head {
    position: absolute;
    background: var(--image-head);
    background-size: contain;
    top: 0;
    left: calc(var(--grid-size) * 5 - var(--grid-size));
    width: calc(var(--grid-size) * 2) !important;
    height: calc(var(--grid-size) * 2) !important;
  }

  .neck {
    position: absolute;
    background: var(--image-pendant);
    background-size: contain;
    top: calc((var(--grid-size) * .25));
    left: calc((var(--grid-size) * 5) + (var(--grid-size) * 1.25));
    width: calc(var(--grid-size) * 1) !important;
    height: calc(var(--grid-size) * 1) !important;
  }

  .torso {
    position: absolute;
    background: var(--image-torso);
    background-size: contain;
    top: calc(var(--grid-size) * 2);
    left: calc((var(--grid-size) * 5) - (var(--grid-size)));
    width: calc(var(--grid-size) * 2) !important;
    height: calc(var(--grid-size) * 3) !important;
  }

  .weapon {
    background: var(--image-weapon);
    background-size: contain;
    width: calc(var(--grid-size) * 2) !important;
    height: calc(var(--grid-size) * 4) !important;
  }

  .right-hand,
  .alt-right-hand {
    top: calc(var(--grid-size) * 1.25);
    left: calc((var(--grid-size) * 5) - (var(--grid-size) * 4.5));
    position: absolute;
  }

  .left-hand,
  .alt-left-hand {
    top: calc(var(--grid-size) * 1.25);
    left: calc((var(--grid-size) * 5) + (var(--grid-size) * 2.5));
    position: absolute;
  }

  .ring {
    background: var(--image-pendant);
    background-size: contain;
    width: calc(var(--grid-size) * 1) !important;
    height: calc(var(--grid-size) * 1) !important;
  }

  .right-finger {
    position: absolute;
    top: calc(var(--grid-size) * 5.25);
    left: calc((var(--grid-size) * 5) - (var(--grid-size) * 2.25));
  }

  .left-finger {
    position: absolute;
    top: calc(var(--grid-size) * 5.25);
    left: calc((var(--grid-size) * 5) + (var(--grid-size) * 1.25));
  }

  .waist {
    position: absolute;
    background: var(--image-waist);
    background-size: contain;
    top: calc(var(--grid-size) * 5.25);
    left: calc((var(--grid-size) * 5) - (var(--grid-size)));
    width: calc(var(--grid-size) * 2) !important;
    height: calc(var(--grid-size) * 1) !important;
  }

  .feet {
    position: absolute;
    background: var(--image-feet);
    background-size: contain;
    top: calc(var(--grid-size) * 5.25);
    left: calc((var(--grid-size) * 5) + (var(--grid-size) * 2.5));
    width: calc(var(--grid-size) * 2) !important;
    height: calc(var(--grid-size) * 2) !important;
  }

  .hands {
    position: absolute;
    background: var(--image-hands);
    background-size: contain;
    top: calc(var(--grid-size) * 5.25);
    left: calc((var(--grid-size) * 5) - (var(--grid-size) * 4.5));
    width: calc(var(--grid-size) * 2) !important;
    height: calc(var(--grid-size) * 2) !important;
  }
}

.item {
  position: absolute;
  background: rgba(0, 0, 45, 0.5);

  &:hover {
    background-color: var(--color-blue);
  }

  .selected {
    border: var(--color-blue) 1px solid;
  }

  &-edit {
    position: relative;
    background: rgba(0, 0, 45, 0.5);
  }
}

.socket {
  position: absolute;
  min-width: calc(var(--grid-size));
  min-height: calc(var(--grid-size));
  z-index: 999;
}

.ethereal {
  opacity: 0.5;
}

.item img,
.socket img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 100%;
  max-height: 100%;
}

.empty-socket {
  background: var(--image-empty-socket);
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: center;
}

.tippy-tooltip {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  font-size: 14px;
}

.react-select {
  width: 100% !important;

  &-container {
    color: black !important;
  }
  &__control {}
  &__value-container {}
  &__indicators {}
  &__menu {
    &-list {}
  }
  &__option {}
}

.itemName {
  margin-bottom: 0.5rem;
}

.statDescriptions {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.dgrey {
  color: var(--color-dgrey);
}

.red {
  color: var(--color-red);
}

.green {
  color: var(--color-green);
}

.blue {
  color: var(--color-blue);
}

.gold {
  color: var(--color-gold);
}

.grey {
  color: var(--color-grey);
}

.black {
  color: var(--color-black);
}

.orange {
  color: var(--color-orange);
}

.yellow {
  color: var(--color-yellow);
}

.white {
  color: var(--color-white);
}

@for $i from 0 through 20 {
  .x-#{$i} {
    left: ($i * 32px);
  }

  .y-#{$i} {
    top: ($i * 32px);
  }

  .w-#{$i} {
    width: ($i * 32px);
  }

  .h-#{$i} {
    height: ($i * 32px);
  }
}
